import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Grid
} from "@material-ui/core";
import { compose } from "recompose";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXing } from "@fortawesome/free-brands-svg-icons";
import { SpecificTheme } from "../../theme/default";
import { TeamPageQuery_allContentfulTeam_edges_node } from "../../pages/__generated__/TeamPageQuery";
import { get } from "../../helpers/data";
import { Image } from "../image/Image";

type PublicTeamMemberProps = {
  member: TeamPageQuery_allContentfulTeam_edges_node;
};

type TeamMemberEnhancedProps = {
  member: TeamPageQuery_allContentfulTeam_edges_node;
} & WithStyles<typeof styles>;

const styles = (theme: SpecificTheme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.only("xs")]: {
        width: "96vw",
        marginBottom: "4vh"
      },
      [theme.breakpoints.only("sm")]: {
        width: "80vw",
        marginBottom: "2vh"
      },
      [theme.breakpoints.only("md")]: {
        width: "42vw",
        marginBottom: "10vh"
      },
      [theme.breakpoints.up("lg")]: {
        width: "21vw",
        marginBottom: "10vh"
      }
    },
    cardContent: {
      padding: "10px"
    },
    media: {
      transition: "height 2s",
      transitionDelay: "0.5s",
      "&:hover": {
        height: "100%"
      },
      [theme.breakpoints.down("sm")]: {
        height: "42vh",
        maxHeight: "47vh"
      },
      [theme.breakpoints.only("md")]: {
        height: "31vh",
        maxHeight: "37vh"
      },
      [theme.breakpoints.up("lg")]: {
        height: "21vh",
        maxHeight: "26vh",
        padding: "0px"
      }
    },
    header: {
      fontSize: "1.4rem",
      fontWeight: "normal"
    },
    subheader: {
      fontSize: "1.15rem",
      marginTop: "0.25rem"
    },
    memberDescription: {
      fontSize: "1rem"
    },
    cardActions: {
      height: "100%"
    },
    linkIcon: {
      color: theme.specific.general.mediumPetrol,
      fontSize: "1.5rem",
      float: "right",
      marginRight: "20px",
      "&:hover": {
        fontSize: "2rem"
      }
    },
    email: {
      textDecoration: "none",
      color: theme.specific.general.mediumPetrol,
      marginRight: "20px",
      "&:hover": {
        fontWeight: "bold"
      }
    }
  });

type ComponentProps = TeamMemberEnhancedProps & WithStyles<typeof styles>;

const TeamMember: React.FC<ComponentProps> = ({ classes, member }) => {
  const description = get(
    member,
    "description",
    "childMarkdownRemark",
    "rawMarkdownBody"
  );

  return (
    <Grid item className={classes.card}>
      <Image image={member.image} className={classes.media} />

      <div className={classes.cardContent}>
        <Typography variant="h2" className={classes.header}>
          {member.name}
        </Typography>
        <Typography variant="subtitle1" className={classes.subheader}>
          {member.activity}
        </Typography>

        <ReactMarkdown
          source={description ? description : ""}
          className={classes.memberDescription}
        />

        <div className={classes.cardActions}>
          {member.email && (
            <a href={`mailto:${member.email}`} className={classes.email}>
              {member.email}
            </a>
          )}
          {member.xing && (
            <a
              href={member.xing}
              className={classes.linkIcon}
              title={`Xing Profil ${member.name}`}
            >
              <FontAwesomeIcon icon={faXing} />
            </a>
          )}

          {/*
            member.linkedIn && (
            <a href={member.linkedIn} className={classes.linkIcon}>
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          )}
          {member.twitter && (
            <a href={member.twitter} className={classes.linkIcon}>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          )*/}
        </div>
      </div>
    </Grid>
  );
};

export default compose<ComponentProps, PublicTeamMemberProps>(
  withStyles(styles)
)(TeamMember);
