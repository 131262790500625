import {
  TeamPageQuery,
  TeamPageQuery_allContentfulTeam_edges_node
} from "../pages/__generated__/TeamPageQuery";

export function teamSelector(
  data: TeamPageQuery
): TeamPageQuery_allContentfulTeam_edges_node[] {
  if (data.allContentfulTeam && data.allContentfulTeam.edges) {
    const members = data.allContentfulTeam.edges.reduce((previous, edge) => {
      if (edge && edge.node) {
        return [...previous, edge.node];
      }
      return previous;
    }, []);
    return members;
  }
  return [];
}
