import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { SpecificTheme } from "../../theme/default";
import { get } from "../../helpers/data";
import { TeamImage } from "./__generated__/TeamImage";
import { compose } from "recompose";

type PublicProps = {};

const styles = (theme: SpecificTheme) =>
  createStyles({
    image: {
      [theme.breakpoints.down("md")]: {
        height: "52vh"
      },
      [theme.breakpoints.up("lg")]: {
        height: "42vh"
      },
      width: "100%",
      backgroundColor: theme.specific.backgroundFront,
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    }
  });

type ComponentProps = PublicProps & WithStyles<typeof styles>;

const BackgroundSection: React.FC<ComponentProps> = ({ classes, children }) => {
  const data = useStaticQuery<TeamImage>(
    graphql`
      query TeamImage {
        teambackground: contentfulImages(name: { eq: "teambackground" }) {
          id
          name
          image {
            title
            description
            fluid(maxWidth: 2500) {
              ...ContentfulFluidImage
            }
          }
        }
      }
    `
  );

  const background = get(data, "teambackground", "image", "fluid");

  if (background) {
    // Retrieve corresponding image

    return (
      <BackgroundImage
        Tag="section"
        className={classes.image}
        fluid={background}
        backgroundColor={`#040e18`}
      >
        {children}
      </BackgroundImage>
    );
  }

  return null;
};

export default compose<ComponentProps, PublicProps>(withStyles(styles))(
  BackgroundSection
);
