import React from "react";
import { map } from "lodash-es";
import { graphql } from "gatsby";
import { compose } from "recompose";
import {
  Grid,
  WithStyles,
  withStyles,
  createStyles,
  Hidden
} from "@material-ui/core";
import { teamSelector } from "../helpers/selector";
import TeamMember from "../components/Team/TeamMember";
import MainLayout from "../components/layout/MainLayout";
import SEO from "../components/SEO";
import Container from "../components/general/Container";
import { TeamPageQuery } from "./__generated__/TeamPageQuery";
import { PageProps } from "../typings";
import TeamBackground from "../components/Team/TeamBackground";
import Footer from "../components/general/Footer";
import { SpecificTheme } from "../theme/default";

type TeamProps = {} & PageProps<TeamPageQuery>;

type ComponentProps = TeamProps & WithStyles<typeof styles>;

const TeamPage: React.FC<ComponentProps> = ({ classes, data, location }) => {
  const members = teamSelector(data);

  return (
    <MainLayout location={location} title="Twteam">
      <Container variant="expandedUp" color="primary" padding>
        <Grid container>
          <Grid item xs={12} className={classes.teambackground}>
            <Hidden xsDown>
              <TeamBackground />
            </Hidden>
            <Grid
              container
              justify="space-around"
              alignItems="stretch"
              className={classes.teamGrid}
            >
              {map(members, member => (
                <TeamMember key={member.id} member={member} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />

      {/* SEO, head params here */}
      <SEO
        general={{
          language: "de",
          contentType: "website",
          path: location.pathname,
          title: data.contentfulPages.seoTitle,
          description: data.contentfulPages.seoDescription
        }}
      />
    </MainLayout>
  );
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    teambackground: {},
    teamGrid: {
      [theme.breakpoints.only("xs")]: {},
      [theme.breakpoints.only("sm")]: {
        marginTop: "-42vh"
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "-31vh"
      },
      [theme.breakpoints.up("lg")]: {
        height: "61vh",
        marginTop: "-21vh"
      },
      width: "100%"
    }
  });

export default compose<ComponentProps, {}>(withStyles(styles))(TeamPage);

// TODO: Add linkedIn & twitter again
export const pageQuery = graphql`
  query TeamPageQuery {
    contentfulPages(page: { eq: "Team" }) {
      seoTitle
      seoDescription
    }
    allContentfulTeam(sort: { fields: [order] }) {
      edges {
        node {
          id
          activity
          name
          phone
          email
          xing
          description {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }

          image {
            title
            description
            fluid(maxWidth: 800, quality: 85) {
              ...ContentfulFluidImage
            }
          }
        }
      }
    }
  }
`;
